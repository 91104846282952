module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A Producitve Nerd","short_name":"A Producitve Nerd","start_url":"/","background_color":"#E9F1FF","theme_color":"#E9F1FF","display":"standalone","icon":"manifest-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8c4dc842bf05b401bf167415ed6526e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800}},{"resolve":"gatsby-remark-image-attributes","options":{"styleAttributes":true,"dataAttributes":true}},{"resolve":"gatsby-remark-katex","options":{"strict":"ignore"}}],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"C:\\Users\\nonun\\...Active Code\\new-gatsby-site\\src\\components\\layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
