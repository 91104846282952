// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-content-articles-all-the-apps-that-i-use-mdx": () => import("./../../../src/pages/content/articles/all-the-apps-that-i-use.mdx" /* webpackChunkName: "component---src-pages-content-articles-all-the-apps-that-i-use-mdx" */),
  "component---src-pages-content-articles-books-vs-audiobooks-mdx": () => import("./../../../src/pages/content/articles/books-vs-audiobooks.mdx" /* webpackChunkName: "component---src-pages-content-articles-books-vs-audiobooks-mdx" */),
  "component---src-pages-content-articles-dealing-with-youtube-mdx": () => import("./../../../src/pages/content/articles/dealing-with-youtube.mdx" /* webpackChunkName: "component---src-pages-content-articles-dealing-with-youtube-mdx" */),
  "component---src-pages-content-articles-how-books-have-influenced-my-life-mdx": () => import("./../../../src/pages/content/articles/how-books-have-influenced-my-life.mdx" /* webpackChunkName: "component---src-pages-content-articles-how-books-have-influenced-my-life-mdx" */),
  "component---src-pages-content-articles-how-the-rubiks-cube-ismore-than-a-puzzle-mdx": () => import("./../../../src/pages/content/articles/how-the-rubik's-cube-ismore-than-a-puzzle.mdx" /* webpackChunkName: "component---src-pages-content-articles-how-the-rubiks-cube-ismore-than-a-puzzle-mdx" */),
  "component---src-pages-content-articles-how-to-build-habits-that-stick-mdx": () => import("./../../../src/pages/content/articles/how-to-build-habits-that-stick.mdx" /* webpackChunkName: "component---src-pages-content-articles-how-to-build-habits-that-stick-mdx" */),
  "component---src-pages-content-articles-how-to-fall-asleep-faster-mdx": () => import("./../../../src/pages/content/articles/how-to-fall-asleep-faster.mdx" /* webpackChunkName: "component---src-pages-content-articles-how-to-fall-asleep-faster-mdx" */),
  "component---src-pages-content-articles-how-to-practise-phone-distancing-mdx": () => import("./../../../src/pages/content/articles/how-to-practise-phone-distancing.mdx" /* webpackChunkName: "component---src-pages-content-articles-how-to-practise-phone-distancing-mdx" */),
  "component---src-pages-content-articles-my-note-taking-workflow-mdx": () => import("./../../../src/pages/content/articles/my-note-taking-workflow.mdx" /* webpackChunkName: "component---src-pages-content-articles-my-note-taking-workflow-mdx" */),
  "component---src-pages-content-articles-online-vs-offline-studying-student-perpective-mdx": () => import("./../../../src/pages/content/articles/online-vs-offline-studying-student-perpective.mdx" /* webpackChunkName: "component---src-pages-content-articles-online-vs-offline-studying-student-perpective-mdx" */),
  "component---src-pages-content-articles-rr-effectively-using-the-daily-note-mdx": () => import("./../../../src/pages/content/articles/rr-effectively-using-the-daily-note.mdx" /* webpackChunkName: "component---src-pages-content-articles-rr-effectively-using-the-daily-note-mdx" */),
  "component---src-pages-content-articles-rr-intro-and-uses-mdx": () => import("./../../../src/pages/content/articles/rr-intro-and-uses.mdx" /* webpackChunkName: "component---src-pages-content-articles-rr-intro-and-uses-mdx" */),
  "component---src-pages-content-articles-rr-weekly-reviews-and-queries-mdx": () => import("./../../../src/pages/content/articles/rr-weekly-reviews-and-queries.mdx" /* webpackChunkName: "component---src-pages-content-articles-rr-weekly-reviews-and-queries-mdx" */),
  "component---src-pages-content-articles-the-myth-about-programming-mdx": () => import("./../../../src/pages/content/articles/the-myth-about-programming.mdx" /* webpackChunkName: "component---src-pages-content-articles-the-myth-about-programming-mdx" */),
  "component---src-pages-content-articles-why-is-sleep-so-necessary-mdx": () => import("./../../../src/pages/content/articles/why-is-sleep-so-necessary.mdx" /* webpackChunkName: "component---src-pages-content-articles-why-is-sleep-so-necessary-mdx" */),
  "component---src-pages-content-books-flipped-mdx": () => import("./../../../src/pages/content/books/flipped.mdx" /* webpackChunkName: "component---src-pages-content-books-flipped-mdx" */),
  "component---src-pages-content-books-how-to-take-smart-notes-mdx": () => import("./../../../src/pages/content/books/how-to-take-smart-notes.mdx" /* webpackChunkName: "component---src-pages-content-books-how-to-take-smart-notes-mdx" */),
  "component---src-pages-content-books-the-alchemist-mdx": () => import("./../../../src/pages/content/books/the-alchemist.mdx" /* webpackChunkName: "component---src-pages-content-books-the-alchemist-mdx" */),
  "component---src-pages-content-books-the-four-hour-work-week-mdx": () => import("./../../../src/pages/content/books/the-four-hour-work-week.mdx" /* webpackChunkName: "component---src-pages-content-books-the-four-hour-work-week-mdx" */),
  "component---src-pages-content-newsletters-newsletter-1-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-1.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-1-mdx" */),
  "component---src-pages-content-newsletters-newsletter-10-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-10.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-10-mdx" */),
  "component---src-pages-content-newsletters-newsletter-11-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-11.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-11-mdx" */),
  "component---src-pages-content-newsletters-newsletter-12-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-12.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-12-mdx" */),
  "component---src-pages-content-newsletters-newsletter-13-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-13.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-13-mdx" */),
  "component---src-pages-content-newsletters-newsletter-14-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-14.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-14-mdx" */),
  "component---src-pages-content-newsletters-newsletter-15-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-15.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-15-mdx" */),
  "component---src-pages-content-newsletters-newsletter-2-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-2.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-2-mdx" */),
  "component---src-pages-content-newsletters-newsletter-3-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-3.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-3-mdx" */),
  "component---src-pages-content-newsletters-newsletter-4-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-4.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-4-mdx" */),
  "component---src-pages-content-newsletters-newsletter-5-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-5.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-5-mdx" */),
  "component---src-pages-content-newsletters-newsletter-6-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-6.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-6-mdx" */),
  "component---src-pages-content-newsletters-newsletter-7-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-7.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-7-mdx" */),
  "component---src-pages-content-newsletters-newsletter-8-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-8.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-8-mdx" */),
  "component---src-pages-content-newsletters-newsletter-9-mdx": () => import("./../../../src/pages/content/newsletters/newsletter-9.mdx" /* webpackChunkName: "component---src-pages-content-newsletters-newsletter-9-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-template-blog-post-js": () => import("./../../../src/template/blog-post.js" /* webpackChunkName: "component---src-template-blog-post-js" */),
  "component---src-template-tags-js": () => import("./../../../src/template/tags.js" /* webpackChunkName: "component---src-template-tags-js" */)
}

